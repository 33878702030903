import React, { useEffect, useState } from 'react'
import { withStyles, createStyles, TextField, Button } from '@material-ui/core'

import { Link } from 'gatsby'

import Input from './Input'
import SubmitButton from './SubmitButton'

import iosLoader from '../../../images/ios-loader.gif'

import appStoreButton from '../../../images/availableOnApple.png'

const paddingY = '0.875rem'

const paddingX = '1.25rem'

const avocadoFinanceUrl = process.env.GATSBY_AF_URL
const countApiName = process.env.GATSBY_COUNT_API_KEY
const waitlistName = process.env.GATSBY_WAITLIST_KEY
const emailValidateApiKey = process.env.GATSBY_WHOIS_API_KEY
const linkedInUrl = process.env.LINKED_IN_URL

const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development'

const isEmailAddress = (x) =>
  /^[-a-z0-9~!$%^&*_=+}{'?]+(\.[-a-z0-9~!$%^&*_=+}{'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i.test(
    x,
  )

const apiUrl = `https://avocado-finance.app.waitlisted.co/api/v2/reservations`

const styles = (theme) =>
  createStyles({
    group: {
      position: 'relative',
      marginBottom: '2.75rem',
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        marginBottom: '2.75rem',
      },
      [theme.breakpoints.up('lg')]: {
        marginBottom: '2.3125rem',
      },
    },
    errorMessage: {
      margin: '0',
      fontFamily: 'Montserrat',
      fontWeight: '400',
      fontSize: 12,
      color: '#FF0020',
      position: 'absolute',
      left: '25px',
      top: '55px',
      [theme.breakpoints.up('md')]: {
        fontSize: 12,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 12,
      },
    },
    confirmedText: {
      fontFamily: 'Montserrat',
      fontWeight: '800',
      fontSize: 14,
      [theme.breakpoints.up('md')]: {
        fontSize: 14,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 14,
      },
    },
    inputGroup: {
      border: '0.0187rem solid rgba(255,0,128,1)',
      borderRadius: '3.125rem',
      boxShadow: '0px 0px 10px rgba(255, 0, 128, 0.1)',
      width: '100%',
      // maxWidth: '19.4375rem',
    },
    input: {
      [theme.breakpoints.up('lg')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
      },
      padding: `${paddingY} 2.8125rem ${paddingY} 1.4375rem`,
      fontSize: '14px',
    },
    button: {
      [theme.breakpoints.up('lg')]: {
        borderRadius: '6.25rem',
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '17px',
        textAlign: 'center',
        boxShadow: 'none',
        paddingLeft: paddingX,
        paddingRight: paddingX
      },
      [theme.breakpoints.up('md')]: {
        borderRadius: '6.25rem',
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '17px',
        textAlign: 'center',
        boxShadow: 'none',
        paddingLeft: paddingX,
        paddingRight: paddingX,
      },
      borderRadius: '6.25rem',
      fontWeight: 700,
      fontSize: '0.875rem',
      lineHeight: '17px',
      textAlign: 'center',
      boxShadow: 'none',
      paddingLeft: paddingX,
      paddingRight: paddingX,
      width: 192,
      height: "40px"
    },
    link: {
      textDecoration: 'none',
    },
    loader: {
      width: '25px'
    },
    appButton: {
      width: '140px',
      display: 'block',
      marginTop: '20px',
    }
  })

const Contact = ({ classes }) => {
  const [emailValue, setEmailValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const [isPhone, setIsPhone] = useState(false)

  useEffect(() => {
    setIsPhone(window.innerWidth <= 599)
    window.addEventListener("resize", handleResize);
  }, [])

  const handleResize = (e) => {
    setIsPhone(window.innerWidth <= 599)
  }

  const sendGridVerification = (emailAddress, code) => {
    return fetch('https://af-sendgrid-proxy.herokuapp.com/v3/mail/send', {
      method: 'POST',
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        personalizations: [
          {
            to: [
              {
                email: emailAddress,
              },
            ],
            dynamic_template_data: {
              refCode: code,
              afUrl: avocadoFinanceUrl,
              linkedInUrl: linkedInUrl,
            },
          },
        ],
        asm: {
          group_id: 121276,
        },
        nickname: 'Avocado Finance',
        from: {
          email: 'support@avocadofinance.io',
          name: 'Avocado Finance',
        },
        reply_to: {
          email: 'support@avocadofinance.io',
          name: 'Avocado Finance',
        },
        address: '944 Ashbridge Lane',
        address_2: '',
        city: 'Harbor City',
        state: 'California',
        zip: '90710',
        country: 'United States',
        template_id: 'd-395f100ed59e4d7db3a8386157600dee',
      }),
    })
  }

  const createReservation = () => {
    window.dataLayer.push({ event: 'clickedSignUp' })
    return fetch(apiUrl, {
      method: 'POST',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache',
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
        api_key: '5ce7b7c31c8a1a42d70e81b4d436cc38',
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify({
        email: emailValue,
        affiliate: '123',
      }), // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.errors) {
          setIsLoading(false)
          setIsConfirmed(false)
          setErrorMessage('You are already on the waitlist.')
        } else {
          sendGridVerification(emailValue, data.affiliate)
          setIsLoading(false)
          setIsConfirmed(true)
        }
      })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    if (!isLoading && !isConfirmed) {
      if (isEmailAddress(emailValue)) {
        setErrorMessage(false)
        setIsLoading(true)
        createReservation()
      } else {
        setIsLoading(false)
        setErrorMessage('Please enter a valid email address.')
      }
    } else {
      return
    }
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSubmit(e)
    }
  }

  return (
    <div>
      <Link to={isPhone ? 'https://avocadofinance-alternate.app.link' : '/get-app'} className={classes.link}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
        >
          {
            // isLoading
            //   ? <img className={classes.loader} src={iosLoader} />
            //   : isConfirmed ? 'Done' :
            'Sign Up'
          }
        </Button>
        <img className={classes.appButton} src={appStoreButton} />
      </Link>
    </div>
  )
}

export default withStyles(styles)(Contact)
