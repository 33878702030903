import React, { Component } from 'react'
import { createStyles, TextField, withStyles } from '@material-ui/core'

const styles = (theme) => {
  const paddingY = '0.875rem'
  return createStyles({
    inputGroup: {
      border: '0.0187rem solid rgba(255,0,128,1)',
      borderRadius: '3.125rem',
      boxShadow: '0px 0px 10px rgba(255, 0, 128, 0.1)',
      maxWidth: '19.4375rem',
    },
    input: {
      [theme.breakpoints.up('lg')]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1rem',
      },
      padding: `${paddingY} 2.8125rem ${paddingY} 1.4375rem`,
      fontSize: '14px',
    },
  })
}

export class Input extends Component {
  render() {
    const { classes } = this.props
    return (
      <TextField
        className={classes.inputGroup}
        inputProps={{ className: classes.input }}
        InputProps={{ disableUnderline: true }}
        placeholder="Enter Email"
      ></TextField>
    )
  }
}

export default withStyles(styles)(Input)
