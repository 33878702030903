import { createStyles, Button, withStyles } from '@material-ui/core'
import React, { Component } from 'react'

const styles = (theme) => {
  const paddingX = '1.25rem'
  return createStyles({
    button: {
      [theme.breakpoints.up('lg')]: {
        borderRadius: '6.25rem',
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '17px',
        textAlign: 'center',
        marginLeft: '0.75rem',
        boxShadow: 'none',
        paddingLeft: paddingX,
        paddingRight: paddingX,
      },
      [theme.breakpoints.up('md')]: {
        borderRadius: '6.25rem',
        fontWeight: 700,
        fontSize: '0.875rem',
        lineHeight: '17px',
        textAlign: 'center',
        marginLeft: '0.75rem',
        boxShadow: 'none',
        paddingLeft: paddingX,
        paddingRight: paddingX,
      },
      borderRadius: '6.25rem',
      fontWeight: 700,
      fontSize: '0.875rem',
      lineHeight: '17px',
      textAlign: 'center',
      marginLeft: '0.75rem',
      boxShadow: 'none',
      paddingLeft: paddingX,
      paddingRight: paddingX,
    },
  })
}

export class SubmitButton extends Component {
  render() {
    const { classes } = this.props
    return (
      <Button
        type="submit"
        className={classes.button}
        variant="contained"
        color="primary"
      >
        Sign Up for Waitlist
      </Button>
    )
  }
}

export default withStyles(styles)(SubmitButton)
